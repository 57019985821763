<template>
    <!-- 场地管理 -->
    <div>
        <el-row :gutter="10">
            <!-- 内容 -->
            <center-res-article
                v-for="item in field"
                :key="item.id"
                :item="item"></center-res-article>
            <!-- 添加区域 -->
            <el-col :span="12">
                <a href="javascript:;" 
                    class="add_datum_box"
                    @click="$router.push('/center/field/info')">
                    <div class="add_datum_area">
                        <i class="el-icon-plus"></i>
                        <div>新增相机</div>
                    </div>
                </a>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import centerResArticle from '@/components/centerResArticle'

export default {
    data(){
        return {
            field:[
                {
                    img:"field/858419.jpg",
                    title:"幻城摄影基地",
                    address:"广州市白云区",
                    price:"200/小时",
                    isShelf:1,
                },
            ],
        }
    },
    components:{
        centerResArticle
    },
}
</script>

<style scoped>
/* element-ui */
.el-col{
    margin-bottom: 15px
}

/* 新增盒子 */
.add_datum_box{
    display: block;
    width: 100%;
    height: 187px;
    border: 1px dashed #ddd;
    border-radius: 4px;
    color:#909399;
    text-align: center;
    position: relative;
}
/* 新增区域 */
.add_datum_box>.add_datum_area{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
/* 图标 */
.add_datum_box>.add_datum_area>i{
    font-size: 35px;
    margin-bottom: 10px;
}
</style>
